import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c70c72c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_list = _resolveComponent("table-list")!

  return (_openBlock(), _createBlock(_component_table_list, {
    columns: _ctx.columns,
    apis: { list: _ctx.apiUrl },
    "is-upload": true,
    type: "audio",
    "list-columns": _ctx.listColumns
  }, {
    default: _withCtx(({ record }) => [
      _createElementVNode("audio", {
        src: record,
        controls: ""
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["columns", "apis", "list-columns"]))
}